:root {
    height: 100%;
    --mycyan: #559;
    --title-size: calc(20px + 1vmin);
    --title-paragraph-size: calc(15px + 0.5vmin);
}

#root {
    height: 100%;
    top: 0;
    left: 0
}


iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: block;
    line-height: 0;
}

@media screen and (min-width: 601px) {
    :root {
        height: 100%;
        --mycyan: #559;
        --title-size: 25px;
        --title-paragraph-size: 15px;
        --title-section-size: 20px;
        --title-list-item-size: 15px;
        --list-text-size: 12px;
        --chip-row-width: 60%;
        --list-horizontal-padding: 20px;
    }

}

@media screen and (max-width: 600px) {
    :root {
        height: 100%;
        --mycyan: #559;
        --title-size: 18px;
        --title-paragraph-size: 15px;
        --title-section-size: 16px;
        --title-list-item-size: 14px;
        --list-text-size: 12px;
        --chip-row-width: 80%;
        --list-horizontal-padding: 7%;
    }
}

.Home {
    font-family: 'Segoe Print', 'Century Gothic', Calibri;
    /* src: local('bahnschrift'), url(./../../../public/bahnschrift.ttf) format('ttf'); */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
    color: black;
}

.Home-Header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    padding: 10px;
}

.w50 {
    width: 50%;
}

.w60 {
    width: 60%;
}


.w75 {
    width: 75%;
}

.Title-Text {
    font-size: var(--title-size);
    font-weight: bold;
    padding: 10px;
}

.Title-Paragraph {
    text-align: center;
    font-size: var(--title-paragraph-size);
}

.Title-Paragraph a {
    text-decoration-line: underline;
    color: var(--mycyan);
    font-weight: bold;
}

.Section-Title {
    font-size: var(--title-section-size);
    font-weight: bold;
    color: #0AF;
}

.Container-Section {
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0px 2%;
}

a {
    font-weight: bold;
    text-decoration-line: underline;
    color: var(--mycyan);
}

.Profile-Image {
    width: 200px;
    height: 200px;
    border-radius: 100px;
    object-fit: cover;
}

.List-Parent {
    padding: 0% var(--list-horizontal-padding);
    margin: 0%;
    list-style: outside;
}

.List-Parent>li {
    padding: 5px 0px;
    font-size: var(--list-text-size);
}

.List-Parent>li>b {
    font-size: var(--title-list-item-size);
}

.Chip-Row {
    width: var(--chip-row-width);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0px 10px;
}

.Chip-Row>* {
    justify-content: center;
    flex: 1;
}

.chip {
    text-decoration: none;
    margin: 5px 0px;
    display: flex;
    border-radius: 25px;
    padding: 5px 10px;
    align-content: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 5px
}

.chip>img {
    width: 20px;
    height: 20px;
}

.chip text {
    font-family: 'Century Gothic';
    font-weight: bold;
    font-size: 12px;
}

.gmail {
    background-color: #EA4335;
}

.cv {
    background-color: #217C95;
}

.soverflow {
    background-color: #F48024;
}

.linkedin {
    background-color: #0077B5;
}

.github {
    background-color: #24292E;
}

.website {
    background-color: #217C95;
}

/* Century Gothic Regular */
@font-face {
    font-family: "Century Gothic";
    src: url('/public/fonts/GOTHIC.TTF') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Century Gothic";
    src: url('/public/fonts/GOTHICI.TTF') format('truetype');
    font-weight: normal;
    font-style: italic;
}

/* Century Gothic Bold */
@font-face {
    font-family: "Century Gothic";
    src: url('/public/fonts/GOTHICB.TTF') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Century Gothic";
    src: url('/public/fonts/GOTHICBI.TTF') format('truetype');
    font-weight: 700;
    font-style: italic;
}

/* Segoe Script Regular */
@font-face {
    font-family: "Segoe Script";
    src: url('/public/fonts/segoesc.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* Segoe Script Bold */
@font-face {
    font-family: "Segoe Script";
    src: url('/public/fonts/segoescb.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

/* Segoe Print Regular */
@font-face {
    font-family: "Segoe Print";
    src: url('/public/fonts/segoepr.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* Segoe Print Bold */
@font-face {
    font-family: "Segoe Print";
    src: url('/public/fonts/segoeprb.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
